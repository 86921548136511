import AuthorityBuilder from 'pojos/AuthorityBuilder.js'

const SellerReputation = new AuthorityBuilder({
	accessGroup: 'SELLER_REPUTATION',
	accessType: ['ACCESS', 'READ']
})

const SellerReputationExport = new AuthorityBuilder({
	accessGroup: 'SELLER_REPUTATION_EXPORT',
	accessType: ['READ']
})

export default [
	SellerReputation,
	SellerReputationExport
]
