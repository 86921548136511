import Configuration from './configurations/'
import Dashboard from './dashboard/'
import Orders from './orders/'
import Products from './products/'
import Sac from './sac/'
import Reports from './reports/'
import Promotion from './promotion/'
import Reputation from './reputation/'

const baseAuthority = 'PANEL_SELLER'

const Authorities = [
	...Configuration,
	...Dashboard,
	...Orders,
	...Products,
	...Sac,
	...Reports,
	...Promotion,
	...Reputation
]

Authorities.forEach(auth => auth.setBaseAuthority(baseAuthority))

export default Authorities
