<template>
	<div class="mkt-select-multiple" v-click-outside="closeOptionList" :class="{ disabled: blDisabled }">
		<md-field :class="{ 'md-focused': isFocused }">
			<mkt-label class="mkt-field__label" v-if="txLabel" :tx-label="txLabel"></mkt-label>
			<md-input type="hidden" name="labelInput" :placeholder="txPlaceholder" :value="selectMultipleModel"></md-input>
			<div
				class="mkt-select-multiple__field" @click="openList" :disabled="blDisabled"
			>
			<div class="mkt-select-multiple__field__container" v-if="selectMultipleModel.length > 0">
				<mkt-tag-list
					:lt-tags="selectMultipleModel"
					:fn-option-text="getOptionText"
					@mkt-tag-list:remove="remove"
				>
				</mkt-tag-list>
			</div>
		</div>
			<span class="mkt-select-multiple__list--icon" @click="toggleList">
				<mkt-icon :tx-icon="isListOpen ? 'icon-arrow_drop_up' : 'icon-arrow_drop_down'" :bl-hover="true" tx-font-size="12px"></mkt-icon>
			</span>
		</md-field>
			<div class="mkt-select-multiple__content" v-if="isListOpen">
				<slot>
					<md-content class="md-scrollbar mkt-raised">
						<div class="mkt-select-multiple__filter">
							<div class="mkt-select-multiple__filter__input">
								<input
									v-model="optionFilter"
									v-focus
									placeholder="Filtrar pelo nome"
									@keydown.down.prevent="down"
									@keydown.up.prevent="up"
									@keydown.enter.prevent="enter"
									@keydown.esc.prevent="esc"
								/>
								<mkt-icon tx-icon="icon-search2" tx-icon-color="#757575" class="mkt-select-multiple__filter__input--icon" :bl-hover="false" tx-font-size="16px"></mkt-icon>
							</div>
						</div>
						<ul class="mkt-select-multiple__list">
							<li class="mkt-select-multiple__list__item mkt-select-multiple__list__item--select-all"
							@click="selectAll" key="select_all" :class="activeClass('select_all')"
							@mousemove="setActive('select_all')" id="select_all" v-if="selectAllOption">
							Todos
						</li>
							<li
								class="mkt-select-multiple__list__item"
								v-for="(option, $index) in filteredList"
								:key="$index"
								:class="activeClass($index)"
								@click="selectOption(option, $index)"
								@mousemove="setActive($index)"
								:id="$index"
							>
							<div v-html="highlightModel(getOptionText(option, $index))"></div>
						</li>
					</ul>
				</md-content>
			</slot>
		</div>
	</div>
</template>

<script>
	import PropConfig from 'pojos/PropConfig'
	import MktTagList from 'components/generics/mktTag/mktTagList'
	import _ from 'lodash-core'
	import { incrementListIndex, decrementListIndex } from 'services/selectService'
	import DOMPurify from 'dompurify'

	export default {
		name: 'mkt-select-multiple',
		components: {
			MktTagList
		},
		props: {
			txId: new PropConfig({ type: String }),
			ltOptions: new PropConfig({ type: Array, required: false, valueDefault: () => [] }),
			fnOnClick: new PropConfig({ type: Function, required: false }),
			fnOnClear: new PropConfig({ type: Function, required: false }),
			optionText: new PropConfig({ type: [String, Function], required: false }),
			optionKey: new PropConfig({ type: String, required: false, valueDefault: 'id' }),
			optionValue: new PropConfig({ type: String, required: false }),
			txLabel: new PropConfig({ type: String }),
			txPlaceholder: new PropConfig({ type: String }),
			blDisabled: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
			value: new PropConfig({ required: false, valueDefault: () => [] }),
			selectWhenClickOutside: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
			selectAllOption: new PropConfig({ type: Boolean, required: false, valueDefault: false })
		},
		data() {
			return {
				isListOpen: false,
				current: undefined,
				optionFilter: '',
				events: {
					remove: (index) => this.$emit('mkt-select-multiple:remove', index),
					updateModelValue: (val) => this.$emit('input', val)
				}
			}
		},
		computed: {
			selectMultipleModel() {
				const value = this.value instanceof Array ? this.value : [this.value]
				if (this.optionValue) {
					return this.ltOptions.filter(option => value.includes(option[this.optionValue]))
				} else {
					return value
				}
			},
			filteredList() {
				const differenceBy = _.differenceBy(this.ltOptions, this.selectMultipleModel, this.optionKey)
				return differenceBy.filter((option, index) => {
					return this.getOptionText(option, index).toLowerCase().includes(this.optionFilter.toLowerCase())
				})
			},
			isFocused() {
				return this.isListOpen
			}
		},
		methods: {
			getOptionText(option, $index) {
				if (this.optionText) {
					return this.optionText instanceof Function ? this.optionText(option, $index) : _.get(option, this.optionText)
				}
				return option
			},
			getOptionValue(option) {
				return this.optionValue ? option[this.optionValue] : option
			},
			highlightModel(template) {
				if (!this.optionFilter) return template

				const escapedFilter = this.optionFilter.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
				const viewValueRegex = new RegExp(escapedFilter, 'gi')
				return DOMPurify.sanitize(template.replace(viewValueRegex, '<strong>$&</strong>'))
			},
			selectOption(option, index) {
				if (option === 'SELECT_ALL') {
					this.selectAll()
					return
				}

				const value = [...this.value]
				value.push(this.getOptionValue(option))
				this.events.updateModelValue(value)
				if (this.fnOnClick) {
					this.fnOnClick(option, index)
				}

				if (this.filteredList.length <= 0) {
					this.closeOptionList()
				}
			},
			selectAll() {
				const allValues = this.ltOptions.map(option => this.getOptionValue(option))
				this.events.updateModelValue(allValues)
				if (this.fnOnClick) {
					this.fnOnClick('SELECT_ALL', -1)
				}
				this.closeOptionList()
			},
			toggleList() {
				if (this.blDisabled) {
					return
				}
				this.isListOpen = !this.isListOpen
			},
			openList() {
				if (this.blDisabled) {
					return
				}
				this.isListOpen = true
			},
			closeOptionList() {
				this.isListOpen = false
				this.current = null
				this.optionFilter = ''
			},
			activeClass(index) {
				return {
					active: this.current === index
				}
			},
			setActive(index) {
				this.current = index
			},
			remove(index, event, obTag) {
				event.preventDefault()
				const valueIndex = this.value.findIndex(v => v === (obTag[this.optionKey] || obTag))
				this.events.remove(this.value.splice(valueIndex, 1))
				this.closeOptionList()
			},
			up() {
				if (!this.isListOpen) {
					return
				}

				if (this.current > this.filteredList.length - 1) {
					this.current = this.filteredList.length - 1
				} else {
					this.current = decrementListIndex(this.current)
				}
			},
			down() {
				if (!this.isListOpen) {
					return
				}

				this.current = incrementListIndex(this.current, this.filteredList.length - 1)
			},
			esc() {
				this.closeOptionList()
			},
			enter(e) {
				if (this.isListOpen && this.current !== undefined) {
					e.stopPropagation()
					this.selectOption(this.filteredList[this.current], this.current)
				}
			}
		}
	}
</script>
<style lang="scss" src="./mktSelectMultiple.scss"></style>