
import { USER_VALID, SESSION_EXPIRE } from 'constants/LocalStorageKeys.js'
import store from 'src/store/store'
import { redirect } from 'services/routesService'
import { ROUTE_LOGIN, ROUTE_REPUTATION } from 'constants/RouterNames'
import { eventEmit, EVENT_UPDATE_SESSION } from 'services/eventBusService'
import { SET_END_SESSION, SET_LOGGED_USER_MUTATION, SET_REDIRECT_TO_LOGIN_MUTATION } from 'constants/MutationTypes'

window.addEventListener('storage', function(event) {
	const { key, newValue: value } = event
	switch (key) {
	case USER_VALID:
		if (value !== 'true' && store.getters.isLoggedIn)	 {
			store.commit(SET_LOGGED_USER_MUTATION, null)
			store.commit(SET_END_SESSION)
			store.commit(SET_REDIRECT_TO_LOGIN_MUTATION, true)
			redirect(ROUTE_LOGIN)
		} else if (value === 'true' && !store.getters.isLoggedIn) {
			redirect(ROUTE_REPUTATION)
		}
		break
	case SESSION_EXPIRE:
		if (value) eventEmit(EVENT_UPDATE_SESSION)
		break
	default:
	}
})
