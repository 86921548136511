<template>
  <md-field :class="{ 'md-invalid': hasErrors, 'md-read-only': blReadOnly }" class="mkt-date-input">
		<mkt-label
			v-if="txLabel" 
			class="mkt-field__label"
			:txLabel="txLabel"
			:bl-required="blRequired"
			:txObservation="txObservation"
		></mkt-label>
		<md-input
			class="mkt-date-input__date"
			@keyup.esc="modelDate = null"
			:placeholder="txPlaceholder"
			:disabled="blDisabled || blReadOnly"
			type="date"
			:required="blRequired"
			:id="txId"
			v-model="modelDate"
			@blur="validateField"
		></md-input>
		<span class="md-error" v-if="hasErrors">{{formValidationError || 'Campo obrigatório'}}</span>
  </md-field>
</template>

<script>
import PropConfig from 'pojos/PropConfig'
import InputType from 'domains/InputType'

export default {
	name: 'mkt-date-input',
	props: {
		txLabel: new PropConfig({ type: String }),
		txPlaceholder: new PropConfig({ type: String, valueDefault: ' ' }),
		txMask: new PropConfig({ type: String }),
		blRequired: new PropConfig({ type: Boolean, valueDefault: false }),
		blDisabled: new PropConfig({ type: Boolean }),
		blReadOnly: new PropConfig({ type: Boolean, required: false, valueDefault: false }),
		nrMaxlength: new PropConfig({ type: Number, valueDefault: 10 }),
		txId: new PropConfig({ type: String, required: false }),
		value: new PropConfig({ required: false }),
		error: new PropConfig({ type: String, required: false }),
		txObservation: new PropConfig({ type: String, required: false })
	},
	data() {
		return {
			tpInputType: InputType.NUMBER,
			hasErrors: false,
			formValidationError: ''
		}
	},
	computed: {
		modelDate: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			}
		}
	},
	methods: {
		validateField() {
			if (!this.value) {
				this.modelDate = null
			}

			if (this.error) {
				return
			}

			this.$emit('blur')
			if (this.blRequired) {
				this.$nextTick(() => {
					this.hasErrors = !this.value
				})
			} else {
				this.hasErrors = false
			}
		}
	},
	watch: {
		error: {
			handler() {
				if (this.error) {
					this.hasErrors = true
					this.formValidationError = this.error
					return
				}

				this.hasErrors = false
				this.formValidationError = ''
			}
		}
	}
}
</script>

<style src="./mktDateInput.scss" lang="scss">
</style>
