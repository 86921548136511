import {
	ROUTE_REPUTATION,
	ROUTE_SELLER_REPUTATION_READ,
	ROUTE_PANEL_COLOMBO_SELLER_REPUTATION_READ,
	ROUTE_SELLER_REPUTATION_REPORT_LIST
} from 'constants/RouterNames'

const ReputationSimplifiedPage = () =>
	import('components/pages/secured/reputation/reputation')
const ReputationPage = () =>
	import('components/pages/secured/reputation/sellerReputation/sellerReputation')
const ReputationReportPage = () =>
	import('components/pages/secured/reputation/sellerReputationReport')

const reputationPageRouter = () =>
	import('components/pages/secured/reputation/index.vue')
const sellerReputationRoute = {
	path: '/reputation',
	redirect: '/seller-reputation',
	component: reputationPageRouter,
	name: ROUTE_REPUTATION.name,
	meta: {
		breadcrumb: { name: 'Reputação dos Sellers' },
		requiresAuthorities: ROUTE_REPUTATION.routerAuth
	},
	children: [
		{
			path: '/reputation-v1',
			component: ReputationSimplifiedPage,
			name: ROUTE_PANEL_COLOMBO_SELLER_REPUTATION_READ.name,
			meta: {
				breadcrumb: { name: 'dashboard' },
				requiresAuthorities:
					ROUTE_PANEL_COLOMBO_SELLER_REPUTATION_READ.routerAuth
			}
		},
		{
			path: '/seller-reputation',
			component: ReputationPage,
			name: ROUTE_SELLER_REPUTATION_READ.name,
			meta: {
				// breadcrumb: { name: 'Reputação do Seller' },
				requiresAuthorities: ROUTE_SELLER_REPUTATION_READ.routerAuth
			}
		},
		{
			path: '/reputation-report-list',
			component: ReputationReportPage,
			name: ROUTE_SELLER_REPUTATION_REPORT_LIST.name,
			meta: {
				breadcrumb: { name: 'Relatório de Reputação' },
				requiresAuthorities: ROUTE_SELLER_REPUTATION_REPORT_LIST.routerAuth
			}
		}
	]
}

export default sellerReputationRoute
