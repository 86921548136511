<template>
	<div class="data-table">
		<mkt-table-header @mkt-table-header:select-items-per-page="onSelectItemsPerPage"
			@mkt-table-header:clear-selected-items="clearSelectedItems" @mkt-table-header:select-all="selectAll"
			:itemsPerPage="pageSize" :nr-selected-items="selectedItems && selectedItems.length"
			:blHideDropdown="blHideDropdown" :tx-selected-item-type-name="txSelectedItemTypeName">
			<slot name="mkt-table-header" :selectedItems="selectedItems" slot="mkt-table-header-slot">
			</slot>
		</mkt-table-header>

		<table>
			<thead>
				<tr>
					<th v-for="field in fields" :key="field.name" @click="sortBy(field)"
						:style="field.width ? { width: field.width } : {}" class="position-relative">
						<div class="d-flex align-items-center justify-content-between w-100">
							<span class="header-title">{{ field.title }}</span>
							<span v-if="field.sortable" class="sort-icon">
								<i v-if="sortKey === field.sortName" :class="{
									'icon-arrow_downward': sortType === 'DESC',
									'icon-arrow_upward': sortType === 'ASC'
								}"></i>
							</span>
						</div>
					</th>
				</tr>
			</thead>
			<tbody class="table-body-wrapper" :class="{ 'loading': loading }">
				<div class="loading-overlay" v-if="loading">
					<div class="spinner"></div>
				</div>
				<tr v-for="(item, index) in displayItems" :key="index">
					<td v-for="field in fields" :key="field.name" :style="field.width ? { width: field.width } : {}">
						<div :class="[
							'd-flex',
							'align-items-center',
							'h-100',
							field.alignment === 'center' ? 'justify-content-center' : '',
							field.alignment === 'right' ? 'justify-content-end' : '',
							!field.alignment || field.alignment === 'left' ? 'justify-content-start' : ''
						]">
							<template v-if="field.customRender">
								<span v-html="field.customRender(item)"></span>
							</template>
							<p v-else :class="[
								field.alignment === 'center' ? 'text-center' : '',
								field.alignment === 'right' ? 'text-right' : '',
								!field.alignment || field.alignment === 'left' ? 'text-left' : ''
							]" class="m-0">
								{{ item[field.name] }}
							</p>
						</div>
					</td>
				</tr>
				<tr v-if="displayItems.length === 0">
					<td :colspan="fields.length" class="no-data-message">
						Não há dados para essa consulta.
					</td>
				</tr>
			</tbody>
		</table>

		<mkt-table-footer :has-export="hasExport" :pagination-data="pageable" :is-processing="loading || loadingExport"
			@changePage="onChangePage" :exportDisabled="hasNoData" @mkt-table:export="initExport">
			<slot name="mkt-table-footer" :selectedItems="selectedItems" slot="mkt-table-footer-slot">
			</slot>
		</mkt-table-footer>
	</div>
</template>

<script>
import axios from 'axios'
import MktTableHeader from './mktTableHeader/mktTableHeader'
import MktTableFooter from './mktTableFooter/mktTableFooter'
import _ from 'lodash'
const OVERRIDE_MESSAGE = 'Operation canceled by newer request'

export default {
	components: { MktTableHeader, MktTableFooter },
	props: {
		fields: {
			type: Array,
			required: true
		},
		apiUrl: {
			type: String,
			required: true
		},
		blHideDropdown: {
			type: Boolean,
			default: false
		},
		txSelectedItemTypeName: {
			type: String,
			default: ''
		},
		customFilters: {
			type: Object,
			default: () => ({})
		},
		hasExport: {
			type: Boolean,
			default: false
		},
		exportFunction: {
			type: Function,
			required: false
		},
		apiUrlExport: {
			// para funcionar, é preciso passar a url da controller, que deve ter 2 endpoints
			// 1-> get /export que vai retornar um objeto do tipo FileExportResultPanelDto
			// 2-> get /file-export/{id} que vai retornar um objeto do tipo FileExportPanelDto
			// sendo assim, o export irá enviar junto todos atributos atualmente utilizados na tabela
			type: String,
			required: false
		}
	},
	watch: {
		customFilters: function() {
			this.fetchData(this.customFilters)
		}
	},
	data() {
		return {
			items: [],
			pageNumber: 1,
			pageSize: 20,
			selectedStatus: '',
			sortKey: '',
			sortType: null,
			totalElements: 0,
			totalPages: 0,
			loading: false,
			loadingExport: false,
			call: null,
			callExport: null,
			selectedItems: [],
			pageable: {
				offset: 0,
				pageNumber: 1,
				pageSize: 20,
				paged: true,
				totalElements: 0
			}
		}
	},
	computed: {
		paginationData() {
			return this.pageable
		},
		hasNoData() {
			return this.items.length === 0
		},
		httpOptions() {
			const params = {
				page: Math.max(1, this.pageNumber),
				size: this.pageSize,
				status: this.selectedStatus
			}

			if (this.sortType) {
				params.sortType = this.sortType
			}
			if (this.sortKey) {
				params.orderBy = this.sortKey
			}

			return { ...params, ...this.customFilters }
		},
		displayItems() {
			return this.items.length > 0 ? this.items : []
		}
	},
	methods: {
		async fetchData(customFilters) {
			if (!this.loading) {
				this.loading = true

				try {
					const response = await this.onSearch(this.apiUrl, {
						...this.httpOptions, ...customFilters
					})

					await new Promise(resolve => setTimeout(resolve, 300))

					if (response.data) {
						this.items = response.data.content
						this.totalElements = response.data.totalElements
						this.totalPages = response.data.totalPages
						this.pageNumber = Math.max(1, response.data.number + 1)
						this.pageSize = response.data.size
						this.pageable = {
							offset: response.data.pageable.offset,
							pageSize: response.data.pageable.pageSize,
							paged: response.data.pageable.paged,
							totalElements: response.data.totalElements,
							currentPage: Math.max(1, response.data.number + 1),
							totalPages: response.data.totalPages
						}
					}
				} catch (error) {
					if (axios.isCancel(error)) {
						console.warn('Request canceled:', error.message)
					} else {
						console.error('Error fetching data:', error)
						this.items = []
						this.totalElements = 0
						this.totalPages = 0
					}
				} finally {
					this.loading = false
				}
			} else {
				if (this.call) {
					this.call.cancel(OVERRIDE_MESSAGE)
					this.call = null
				}
				this.loading = false
				this.fetchData(customFilters)
			}
		},
		async fetchExport(customFilters) {
			if (this.loadingExport) {
				if (this.callExport) {
					this.callExport.cancel(OVERRIDE_MESSAGE)
					this.callExport = null
				}
				this.loadingExport = false
				return this.fetchExport(customFilters)
			}

			this.loadingExport = true
			try {
				const { data } = await this.onExport(`${this.apiUrlExport}/export`, {
					...this.httpOptions,
					...customFilters
				})

				if (!_.get(data, 'exportId')) {
					console.warn('Export ID não encontrado na resposta:', data)
					return
				}

				let status = 'PENDING'
				const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
				let responseOfExport

				while (status !== 'FINISHED') {
					await sleep(1000)
					responseOfExport = await this.onExport(
						`${this.apiUrlExport}/file-export/${_.get(data, 'exportId')}`,
						{
							...this.httpOptions,
							...customFilters
						}
					)

					if (!_.get(responseOfExport, 'data')) {
						console.warn('Dados da exportação não encontrados na resposta:', responseOfExport)
						continue
					}

					status = _.get(responseOfExport, 'data.fileExportStatus')
				}

				const urlDownload = _.get(responseOfExport, 'data.urlDownload')
				if (urlDownload) {
					window.open(urlDownload, '_blank')
				} else {
					console.warn('URL para download não encontrada:', responseOfExport)
				}
			} catch (error) {
				if (axios.isCancel(error)) {
					console.warn('Request canceled:', error.message)
				} else {
					console.error('Error fetching ', error)
				}
			} finally {
				this.loadingExport = false
			}
		},
		onChangePage(newPage) {
			newPage = Math.max(1, newPage)
			if (newPage <= this.totalPages) {
				this.pageNumber = newPage
				this.fetchData()
			}
		},
		initExport() {
			if (typeof this.exportFunction === 'function') {
				this.exportFunction(this.httpOptions)
			} else if (typeof this.apiUrlExport && this.apiUrlExport.length) {
				this.fetchExport(this.httpOptions)
			}
		},
		sortBy(field) {
			if (field.sortable) {
				if (this.sortKey === field.sortName) {
					if (this.sortType === 'DESC') {
						this.sortType = 'ASC'
					} else if (this.sortType === 'ASC') {
						this.sortKey = ''
						this.sortType = null
					} else {
						this.sortType = 'DESC'
					}
				} else {
					this.sortKey = field.sortName
					this.sortType = 'DESC'
				}
				this.pageNumber = 1
				this.fetchData()
			}
		},
		onSearch(apiUrl, httpOptions) {
			if (this.call) {
				this.call.cancel(OVERRIDE_MESSAGE)
				this.call = null
			}
			this.call = axios.CancelToken.source()

			return axios.get(apiUrl, {
				params: httpOptions,
				cancelToken: this.call.token,
				paramsSerializer: params => {
					return Object.entries(params)
						.filter(([_, value]) => value !== null && value !== undefined && value !== '')
						.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
						.join('&')
				}
			})
		},
		onExport(apiUrl, httpOptions) {
			if (this.callExport) {
				this.callExport.cancel(OVERRIDE_MESSAGE)
				this.callExport = null
			}
			this.callExport = axios.CancelToken.source()

			return axios.get(apiUrl, {
				params: httpOptions,
				cancelToken: this.callExport.token,
				paramsSerializer: params => {
					return Object.entries(params)
						.filter(([_, value]) => value !== null && value !== undefined && value !== '')
						.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
						.join('&')
				}
			})
		},
		onSelectItemsPerPage(value) {
			this.pageSize = value
			this.pageNumber = 1
			this.fetchData()
		},
		clearSelectedItems() {
			this.selectedItems = []
		},
		selectAll() {
			this.selectedItems = this.items.slice()
		}
	},
	created() {
		this.fetchData()
	},
	beforeDestroy() {
		if (this.call) {
			this.call.cancel(OVERRIDE_MESSAGE)
		}
	}
}
</script>

<style src="./mktTable2.scss" lang="scss" scoped></style>
