import AuthorityBuilder from 'pojos/AuthorityBuilder.js'

const SellerReputation = new AuthorityBuilder({
	accessGroup: 'SELLER_REPUTATION',
	accessType: ['ACCESS', 'READ']
})

const SellerReputationExport = new AuthorityBuilder({
	accessGroup: 'SELLER_REPUTATION_EXPORT',
	accessType: ['READ']
})

const SellerReputationReport = new AuthorityBuilder({
	accessGroup: 'SELLER_REPUTATION_REPORT_LIST',
	accessType: ['READ']
})

const SellerReputationReportExport = new AuthorityBuilder({
	accessGroup: 'SELLER_REPUTATION_REPORT_LIST_EXPORT',
	accessType: ['READ']
})
export default [
	SellerReputation,
	SellerReputationExport,
	SellerReputationReport,
	SellerReputationReportExport
]
