import Vue from 'vue'

import mktBox from './mktBox/mktBox.vue'
import mktButton from './mktButton/mktButton.vue'
import mktCharacteristicDetailNeeded from './mktCharacteristicDetailNeeded/mktCharacteristicDetailNeeded.vue'
import mktCheckbox from './mktCheckbox/mktCheckbox.vue'
import mktDropdown from './mktDropdown/mktDropdown.vue'
import mktError from './mktError/mktError.vue'
import mktField from './mktField/mktField.vue'
import mktFileUpload from './mktFileUpload/mktFileUpload.vue'
import mktIcon from './mktIcon/mktIcon.vue'
import mktLabel from './mktLabel/mktLabel.vue'
import mktRadio from './mktRadio/mktRadio.vue'
import mktSelect from './mktSelect/mktSelect.vue'
import mktSpacing from './mktSpacing/mktSpacing.vue'
import mktTable from './mktTable/mktTable.vue'
import mktTypeahead from './mktTypeahead/mktTypeahead.vue'
import mktTitle from './mktTitle/mktTitle.vue'
import mktInputEmail from './mktInputEmail/mktInputEmail.vue'
import mktInputDecimal from './mktInputDecimal/mktInputDecimal.vue'
import mktInputMoney from './mktInputMoney/mktInputMoney.vue'
import mktPagination from './mktPagination/mktPaginateData/mktPaginateData.vue'
import mktPaginationList from './mktPagination/mktPaginateList/mktPaginateList.vue'
import mktSelectMultiple from './mktSelectMultiple/mktSelectMultiple.vue'
import mktSimpleFileUpload from './mktSimpleFileUpload/mktSimpleFileUpload.vue'
import mktAlert from './mktAlert/mktAlert.vue'
import mktSwitch from './mktSwitch/mktSwitch.vue'
import mktInputCpfCnpj from './mktInputCpfCnpj/mktInputCpfCnpj.vue'
import mktMaskedInput from './mktMaskedInput/mktMaskedInput.vue'
import mktDataField from './mktDataField/mktDataField.vue'
import mktLineChart from './mktLineChart/mktLineChart.vue'
import mktBarChart from './mktBarChart/mktBarChart.vue'
import mktDoughnutChart from './mktDoughnutChart/mktDoughnutChart.vue'
import mktDateInput from './mktDateInput/mktDateInput.vue'
import mktTimeInput from './mktTimeInput/mktTimeInput.vue'
import mktDateTimeInput from './mktDateTimeInput/mktDateTimeInput.vue'
import mktFileInput from './mktFileInput/mktFileInput.vue'
import mktHtmlTextarea from './mktHtmlTextarea/mktHtmlTextarea.vue'
import mktAttachments from './mktAttachments/mktAttachments.vue'
import mktWarning from './mktWarning/mktWarning.vue'
import mktForm from './mktForm/mktForm.vue'
import subRouteView from './subRouteView/subRouteView.vue'
import mktTable2 from './mktTable2/mktTable2.vue'

Vue.component('mkt-box', mktBox)
Vue.component('mkt-button', mktButton)
Vue.component('mkt-checkbox', mktCheckbox)
Vue.component('mkt-characteristic-detail-needed', mktCharacteristicDetailNeeded)
Vue.component('mkt-dropdown', mktDropdown)
Vue.component('mkt-error', mktError)
Vue.component('mkt-field', mktField)
Vue.component('mkt-file-upload', mktFileUpload)
Vue.component('mkt-icon', mktIcon)
Vue.component('mkt-label', mktLabel)
Vue.component('mkt-radio', mktRadio)
Vue.component('mkt-select', mktSelect)
Vue.component('mkt-spacing', mktSpacing)
Vue.component('mkt-table', mktTable)
Vue.component('mkt-typeahead', mktTypeahead)
Vue.component('mkt-title', mktTitle)
Vue.component('mkt-input-email', mktInputEmail)
Vue.component('mkt-input-decimal', mktInputDecimal)
Vue.component('mkt-input-money', mktInputMoney)
Vue.component('mkt-pagination', mktPagination)
Vue.component('mkt-pagination-list', mktPaginationList)
Vue.component('mkt-select-multiple', mktSelectMultiple)
Vue.component('mkt-simple-file-upload', mktSimpleFileUpload)
Vue.component('mkt-alert', mktAlert)
Vue.component('mkt-switch', mktSwitch)
Vue.component('mkt-input-cpf-cnpj', mktInputCpfCnpj)
Vue.component('mkt-masked-input', mktMaskedInput)
Vue.component('mkt-data-field', mktDataField)
Vue.component('mkt-line-chart', mktLineChart)
Vue.component('mkt-bar-chart', mktBarChart)
Vue.component('mkt-doughnut-chart', mktDoughnutChart)
Vue.component('mkt-date-input', mktDateInput)
Vue.component('mkt-time-input', mktTimeInput)
Vue.component('mkt-date-time-input', mktDateTimeInput)
Vue.component('mkt-file-input', mktFileInput)
Vue.component('mkt-html-textarea', mktHtmlTextarea)
Vue.component('mkt-attachments', mktAttachments)
Vue.component('mkt-form', mktForm)
Vue.component('mkt-warning', mktWarning)
Vue.component('sub-route-view', subRouteView)
Vue.component('mkt-new-table', mktTable2)
