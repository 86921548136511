<template>
	<div class="mkt-table__footer">
		<div class="pagination-info">{{ paginationInfoText }}</div>
		<div class="pagination">
			<button v-if="showFirstPage" @click="onChangePage(1)" class="pagination-button first-page"
				title="Primeira página">
				<i class="icon-chevron-left" />
			</button>
			<button @click="onChangePage(currentPage - 1)" :disabled="isFirstPage" class="pagination-button prev"
				title="Página anterior">
				<i class="icon-arrow_back" />
			</button>
			<button v-for="page in displayedPages" :key="page"
				:class="['page-number', { active: page === currentPage }]" @click="onChangePage(page)">
				{{ page }}
			</button>
			<button @click="onChangePage(currentPage + 1)" :disabled="isLastPage" class="pagination-button next"
				title="Próxima página">
				<i class="icon-arrow_forward" />
			</button>
			<button v-if="showLastPage" @click="onChangePage(totalPages)" class="pagination-button last-page"
				title="Última página">
				<i class="icon-chevron-right" />
			</button>
		</div>
		<div class="export-section">
			<slot name="mkt-table-footer-slot">
				<mkt-button v-if="hasExport" class="export-button" :button="buttonExport"></mkt-button>
			</slot>
		</div>
	</div>
</template>

<script>
import Button from 'pojos/Button'

export default {
	name: 'mkt-table-footer',
	props: {
		paginationData: {
			type: Object,
			required: true
		},
		exportAuthority: {
			type: [String, Array],
			required: false
		},
		hasExport: {
			type: Boolean,
			default: false
		},
		exportDisabled: {
			type: Boolean,
			default: false
		},
		isProcessing: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		currentPage() {
			return this.paginationData.currentPage
		},
		totalPages() {
			return this.paginationData.totalPages
		},
		isFirstPage() {
			return this.currentPage === 1
		},
		isLastPage() {
			return this.currentPage === this.totalPages
		},
		showFirstPage() {
			return this.currentPage > 3
		},
		showLastPage() {
			return this.currentPage < this.totalPages - 2
		},
		paginationInfoText() {
			if (this.paginationData && this.paginationData.pageable) {
				const { offset, to, total } = this.paginationData.pageable
				return total === 0 ? 'Nenhum resultado encontrado' : `Mostrando ${offset} a ${to} de ${total} resultados`
			}
		},
		displayedPages() {
			const range = 2
			let start = Math.max(1, this.currentPage - range)
			let end = Math.min(this.totalPages, this.currentPage + range)

			if (end - start + 1 < 5) {
				if (start === 1) {
					end = Math.min(5, this.totalPages)
				} else if (end === this.totalPages) {
					start = Math.max(1, this.totalPages - 4)
				}
			}

			return Array.from({ length: end - start + 1 }, (_, i) => start + i)
		},
		buttonExport() {
			return new Button({
				label: 'Exportar',
				icon: 'icon-common-file',
				className: 'md-dense mkt-export-button',
				onClick: this.exportData,
				loading: () => this.isProcessing,
				disabled: () => this.exportDisabled,
				hasLoading: true,
				iconSize: '13px',
				loaderColor: '#003C86',
				authorization: this.exportAuthority
			})
		}
	},
	methods: {
		onChangePage(page) {
			if (page >= 1 && page <= this.totalPages) {
				this.$emit('changePage', page)
			}
		},
		exportData() {
			this.$emit('mkt-table:export')
		}
	}
}
</script>

<style src="./mktTableFooter.scss" lang="scss" scoped></style>
