import {
	ROUTE_DASHBOARD,
	ROUTE_PROFILE
} from 'constants/RouterNames'

import renewPasswordGuard from 'src/router/guards/renewPasswordGuard.js'

import productRoute from './product/'
import configurationRoute from './configuration/'
import orderRoute from './order/'
import financialRoute from './financial/'
import sacRoute from './sac/'
import reportRoute from './reports/'
import promotionRoute from './promotion/'
import reputationRoute from './reputation/'
const secured = () => import('components/pages/secured/secured')
const dashboard = () => import('components/pages/secured/dashboard/dashboard')
const profile = () => import('components/pages/secured/profile/profile')

const securedRoute = {
	path: '/secured',
	redirect: '/seller-reputation',
	component: secured,
	beforeEnter: renewPasswordGuard,
	meta: { requiresAuth: true, breadcrumb: { icon: 'icon-home2' } },
	children: [
		{ path: '/dashboard', component: dashboard, name: ROUTE_DASHBOARD.name, meta: { requiresAuthorities: ROUTE_DASHBOARD.routerAuth } },
		{ path: '/profile', component: profile, name: ROUTE_PROFILE.name, meta: { breadcrumb: { name: 'Meu Perfil' }, requiresAuthorities: ROUTE_PROFILE.routerAuth } },
		productRoute,
		configurationRoute,
		orderRoute,
		financialRoute,
		sacRoute,
		reportRoute,
		promotionRoute,
		reputationRoute
	]
}

export default securedRoute
