import store from 'src/store/store'
import { ROUTE_REPUTATION } from 'constants/RouterNames'
import { getLoggedUser } from 'httpServices/userHttpService'
import { SET_LOGGED_USER_MUTATION, SET_REDIRECT_TO_LOGIN_MUTATION } from 'constants/MutationTypes'
import { USER_VALID } from 'constants/LocalStorageKeys.js'

export default function requireAuth(to, from, next) {
	// If user was forcibly sent to Login, disregard session validations
	if (store.getters.wasRedirectToLogin) {
		store.commit(SET_REDIRECT_TO_LOGIN_MUTATION, false)
		next()
		return
	}

	// Verify if the user is authenticated
	const isLoggedIn = store.getters.isLoggedIn && localStorage.getItem(USER_VALID) === 'true'

	if (!isLoggedIn) {
		getLoggedUser().then(user => { // Verify if the user has a session in the backend
			store.commit(SET_LOGGED_USER_MUTATION, user)
			next(`/${ROUTE_REPUTATION.name}`) // If the user already has a session, redirect to the dashboard
		}).catch(() => {
			next() // Otherwise allow him to access the login
		})
	} else {
		next(`/${ROUTE_REPUTATION.name}`) // If the user already has a session, redirect to the dashboard
	}
}
