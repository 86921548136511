var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-table"},[_c('mkt-table-header',{attrs:{"itemsPerPage":_vm.pageSize,"nr-selected-items":_vm.selectedItems && _vm.selectedItems.length,"blHideDropdown":_vm.blHideDropdown,"tx-selected-item-type-name":_vm.txSelectedItemTypeName},on:{"mkt-table-header:select-items-per-page":_vm.onSelectItemsPerPage,"mkt-table-header:clear-selected-items":_vm.clearSelectedItems,"mkt-table-header:select-all":_vm.selectAll}},[_vm._t("mkt-table-header",null,{"slot":"mkt-table-header-slot","selectedItems":_vm.selectedItems})],2),_vm._v(" "),_c('table',[_c('thead',[_c('tr',_vm._l((_vm.fields),function(field){return _c('th',{key:field.name,staticClass:"position-relative",style:(field.width ? { width: field.width } : {}),on:{"click":function($event){return _vm.sortBy(field)}}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100"},[_c('span',{staticClass:"header-title"},[_vm._v(_vm._s(field.title))]),_vm._v(" "),(field.sortable)?_c('span',{staticClass:"sort-icon"},[(_vm.sortKey === field.sortName)?_c('i',{class:{
								'icon-arrow_downward': _vm.sortType === 'DESC',
								'icon-arrow_upward': _vm.sortType === 'ASC'
							}}):_vm._e()]):_vm._e()])])}),0)]),_vm._v(" "),_c('tbody',{staticClass:"table-body-wrapper",class:{ 'loading': _vm.loading }},[(_vm.loading)?_c('div',{staticClass:"loading-overlay"},[_c('div',{staticClass:"spinner"})]):_vm._e(),_vm._v(" "),_vm._l((_vm.displayItems),function(item,index){return _c('tr',{key:index},_vm._l((_vm.fields),function(field){return _c('td',{key:field.name,style:(field.width ? { width: field.width } : {})},[_c('div',{class:[
						'd-flex',
						'align-items-center',
						'h-100',
						field.alignment === 'center' ? 'justify-content-center' : '',
						field.alignment === 'right' ? 'justify-content-end' : '',
						!field.alignment || field.alignment === 'left' ? 'justify-content-start' : ''
					]},[(field.customRender)?[_c('span',{domProps:{"innerHTML":_vm._s(field.customRender(item))}})]:_c('p',{staticClass:"m-0",class:[
							field.alignment === 'center' ? 'text-center' : '',
							field.alignment === 'right' ? 'text-right' : '',
							!field.alignment || field.alignment === 'left' ? 'text-left' : ''
						]},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(item[field.name])+"\n\t\t\t\t\t\t")])],2)])}),0)}),_vm._v(" "),(_vm.displayItems.length === 0)?_c('tr',[_c('td',{staticClass:"no-data-message",attrs:{"colspan":_vm.fields.length}},[_vm._v("\n\t\t\t\t\tNão há dados para essa consulta.\n\t\t\t\t")])]):_vm._e()],2)]),_vm._v(" "),_c('mkt-table-footer',{attrs:{"has-export":_vm.hasExport,"pagination-data":_vm.pageable,"is-processing":_vm.loading || _vm.loadingExport,"exportDisabled":_vm.hasNoData},on:{"changePage":_vm.onChangePage,"mkt-table:export":_vm.initExport}},[_vm._t("mkt-table-footer",null,{"slot":"mkt-table-footer-slot","selectedItems":_vm.selectedItems})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }