<template>
	<div class="login">
		<mkt-box :tpPaddingSize="TypeSize.LARGE" v-cloak>
			<div class="login__header" slot="title">
				<span class="login__header__top">Bem-vindo</span>
				<span class="login__header__middle">Por favor entre com suas informações</span>
			</div>
			<div class="login__content">
				<mkt-form @submit="authorize">
					<mkt-input-email
						tx-label="E-MAIL"
						:bl-required="true"
						tx-placeholder="digite seu e-mail"
						v-model="loginDetails.username"
						tx-field-class="md-login"
					></mkt-input-email>
					<mkt-field
						v-model="loginDetails.password"
						:bl-required="true"
						:tp-field="TypeField.INPUT"
						:tp-input-type="InputType.PASSWORD"
						tx-label="SENHA"
						tx-placeholder="insira sua senha"
						tx-field-class="md-login"
					></mkt-field>
					<mkt-recaptcha
						ref="recaptcha"
						@mkt-recaptcha:verify="updateResponse"
						@mkt-recaptcha:expire="updateResponse"
						:site-key="recaptchaSiteKey"
						:bl-render-on-mount="blEnableCaptcha"
					></mkt-recaptcha>
					<div v-if="loginError" class="login__box__text__error"> Verifique seu email e/ou senha e tente novamente. </div>
					<mkt-button class="submit-button" ref="submitButton" :button="button"></mkt-button>
					<div class="login__box__text">
						<span class="login__box__text__bottom">Esqueceu sua senha?</span>
						<span @click="redirectForgotPassword" class="login__box__text__bottom__link">Alterar senha</span>
					</div>
				</mkt-form>
			</div>
		</mkt-box>
	</div>
</template>
<script>
import TypeField from 'domains/TypeField'
import InputType from 'domains/InputType'
import TypeSize from 'domains/TypeSize'
import Button from 'pojos/Button'

import mktRecaptcha from 'src/components/generics/mktRecaptcha/mktRecaptcha'

import { login } from 'httpServices/sessionHttpService'
import { redirect } from 'services/routesService'
import { ROUTE_FORGOT_PASSWORD, ROUTE_REPUTATION } from 'constants/RouterNames'
import { eventEmit, EVENT_RECAPTCHA_ERROR } from 'services/eventBusService'

import { ENABLE_RECAPTCHA, RECAPTCHA_SITE_KEY, USER_VALID } from 'constants/LocalStorageKeys.js'

const BRUTE_FORCE_HEADER = 'x-brute-protection'
const RECAPTCHA_SITE_KEY_HEADER = 'x-marketplace-recaptcha'

export default {
	components: {
		mktRecaptcha
	},
	data() {
		return {
			TypeSize: TypeSize,
			TypeField: TypeField,
			InputType: InputType,
			loginError: false,
			blEnableCaptcha: (localStorage.getItem(ENABLE_RECAPTCHA) === 'true'),
			recaptchaSiteKey: localStorage.getItem(RECAPTCHA_SITE_KEY),
			loginDetails: {
				username: null,
				password: null
			},
			button: new Button({
				label: 'ENTRAR',
				hasLoading: true,
				type: 'submit'
			})
		}
	},
	methods: {
		updateResponse(recaptchaResponse) {
			this.loginDetails.recaptchaResponse = recaptchaResponse ? { recaptchaResponse } : null
		},
		authorize() {
			this.loginError = false
			if (this.blEnableCaptcha && !this.loginDetails.recaptchaResponse) {
				eventEmit(EVENT_RECAPTCHA_ERROR)
				return
			}

			const promiseLogin = login(this.loginDetails)
				.then((response) => {
					this.setLocalStorageCaptcha(false, null)
					localStorage.setItem(USER_VALID, true)
					redirect(ROUTE_REPUTATION)
				})
				.catch((err) => {
					this.loginError = true
					this.blEnableCaptcha = this.blEnableCaptcha || !!(err && err.response && err.response.headers[BRUTE_FORCE_HEADER])
					this.recaptchaSiteKey = (err && err.response && err.response.headers[RECAPTCHA_SITE_KEY_HEADER]) || this.recaptchaSiteKey
					this.setLocalStorageCaptcha(this.blEnableCaptcha, this.recaptchaSiteKey)

					if (this.blEnableCaptcha && this.recaptchaSiteKey) {
						this.renderCaptcha(this.recaptchaSiteKey)
					}
				})

			this.$refs.submitButton.startLoader(promiseLogin)
		},
		setLocalStorageCaptcha(enableCaptcha, recaptchaSiteKey) {
			localStorage.setItem(ENABLE_RECAPTCHA, enableCaptcha)
			localStorage.setItem(RECAPTCHA_SITE_KEY, recaptchaSiteKey)
		},
		renderCaptcha(recaptchaSiteKey) {
			this.loginDetails.recaptchaResponse = undefined
			this.$nextTick(() => {
				this.$refs.recaptcha.render(recaptchaSiteKey)
			})
		},
		redirectForgotPassword(route) {
			redirect(ROUTE_FORGOT_PASSWORD)
		}
	}
}
</script>

<style src="./login.scss" lang="scss"></style>
